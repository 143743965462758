<template>
  <div>
    <inputSelect
      :key="data"
      :settings="{
        value: services[index].time,
        default: defaultValue,
        values: values
      }"
      :callback="setTime"
      :placeholder="$t('services.duration.title')"
      :label="$t('services.duration.title')"
      :help="$t('services.duration.info')"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: Number
  },
  data() {
    return {
      services: JSON.parse(JSON.stringify(this.$store.state.service.unsaved)),
      defaultValue: "30",
      data: false,
      values: {
        "15": "15 " + this.$tc("dates.name.minutes", 15),
        "30": "30 " + this.$tc("dates.name.minutes", 30),
        "45": "45 " + this.$tc("dates.name.minutes", 45),
        "60": "1 " + this.$tc("dates.name.hours", 1),
        "90": "90 " + this.$tc("dates.name.minutes", 90),
        "120": "2 " + this.$tc("dates.name.hours", 2),
        "180": "3 " + this.$tc("dates.name.hours", 3),
        "240": "4 " + this.$tc("dates.name.hours", 4),
        "300": "5 " + this.$tc("dates.name.hours", 5),
        "360": "6 " + this.$tc("dates.name.hours", 6),
        "420": "7 " + this.$tc("dates.name.hours", 7),
        "480": "8 " + this.$tc("dates.name.hours", 8),
        "540": "9 " + this.$tc("dates.name.hours", 9),
        "600": "10 " + this.$tc("dates.name.hours", 10),
        "660": "11 " + this.$tc("dates.name.hours", 11),
        "720": "12 " + this.$tc("dates.name.hours", 12),
        "780": "13 " + this.$tc("dates.name.hours", 13),
        "840": "14 " + this.$tc("dates.name.hours", 14),
        "900": "15 " + this.$tc("dates.name.hours", 15),
        "960": "16 " + this.$tc("dates.name.hours", 16),
        "1020": "17 " + this.$tc("dates.name.hours", 17),
        "1080": "18 " + this.$tc("dates.name.hours", 18),
        "1140": "19 " + this.$tc("dates.name.hours", 19),
        "1200": "20 " + this.$tc("dates.name.hours", 20),
        "1260": "21 " + this.$tc("dates.name.hours", 21),
        "1320": "22 " + this.$tc("dates.name.hours", 22),
        "1380": "23 " + this.$tc("dates.name.hours", 23)
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setTime(value) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[this.index].time = value;
      this.setSave();
    },
    setSave() {
      this.$store.commit("service/prefetch", this.services);
    }
  }
};
</script>
<style lang="sass" scoped></style>
